// Discounts per quantity (GBP)
export const rslLicenceDiscount1Y = 0.34;
export const mfrLicenceDiscount1Y = 0.74;
export const rslLicenceDiscount3Y = 0.96;
export const mfrLicenceDiscount3Y = 2.11;

// Other discounts (/100)
export const annualBillingDiscount = 0.05;

// FX rate
export const eurFxRate = 1.1878;
export const usdFxRate = 1.2740;
export const audFxRate = 1.9853;

// Discount rate for AUD (/100)
export const usdRowDiscountRate = 0.25;

// Prices of 1 year licence (GBP)
export const rslLicencePrice1Y = 105;
export const mfrLicencePrice1Y = 180;

// Prices of 3 years licence (GBP)
export const rslLicencePrice3Y =  299.25;
export const mfrLicencePrice3Y = 513;