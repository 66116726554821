import React from "react";
import { Result } from "../Result/Result";
import './resultsSection.css';

export const ResultsSection = ({ resellerMargin, resellerTotalPrice, resellerCostPerLicence, mfrTotalPrice, mfrCostPerLicence, currency, quantityExceedLimit }) => {
  const setCurrencySymbol = (text) => {
    if (text === 0 || text === "0.00") {
      const symbols = {
        "GBP": "£ -",
        "EUR": "- €",
        "USD": "- $",
        "AUD": "- A$",
      };
      return symbols[currency];
    }

    const symbols = {
      "GBP": `£ ${text}`,
      "EUR": `${text} €`,
      "USD": `${text} $`,
      "AUD": `${text} A$`,
    };
    
    return symbols[currency];
  }

  return (
    <section>
      <Result title='Cost per licence (Reseller)' text={setCurrencySymbol(resellerCostPerLicence)} />
      <Result title='Cost per licence (MSPR)' text={setCurrencySymbol(mfrCostPerLicence)} />
      <Result title='Total price (Reseller)' text={setCurrencySymbol(resellerTotalPrice)} />
      <Result title='Total price (MSPR)' text={setCurrencySymbol(mfrTotalPrice)} />
      <Result title='Reseller margin' text={quantityExceedLimit ? "- %" : `${resellerMargin} %`} />
    </section>
  );
}
