import React, { useState, useEffect } from "react";
import * as C from '../../constants';
import './form.css';

export const Form = ({ onResultsChange, currency }) => {
  const [licenceType, setLicenceType] = useState('1');
  const [quantity, setQuantity] = useState(1);
  const [quantityExceedLimit, setQuantityExceedLimit] = useState(false);

  const formatNumber = (num) => {
    const truncated = Math.trunc(num * 100) / 100;
    return truncated % 1 === 0 ? truncated.toString() : truncated.toFixed(2);
  };

  useEffect(() => {
    calculateResults();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenceType, quantity]);

  const setFxRate = () => {
    const fxRates = {
      "GBP": 1,
      "EUR": C.eurFxRate,
      "USD": C.usdFxRate,
      "AUD": C.audFxRate,
    };
    
    return fxRates[currency];
  }

  const calculateResults = () => {
    if (quantity > 99) {
      setQuantityExceedLimit(true);
      onResultsChange({
        resellerCostPerLicence: 0,
        resellerMargin: 0,
        resellerTotalPrice: 0,
        mfrTotalPrice: 0,
        mfrCostPerLicence: 0,
        quantityExceedLimit: true,
      });
      return;
    }
    setQuantityExceedLimit(false);

    let resellerInitialLicencePrice = 0;
    let mfrInitialLicencePrice = 0;
    let resellerInitialDiscount = 0;
    let mfrInitialDiscount = 0; 
    let fxRate = 1;
    let discountRate = 0;

    if (licenceType === '1') {
      resellerInitialLicencePrice = C.rslLicencePrice1Y;
      mfrInitialLicencePrice = C.mfrLicencePrice1Y;
      resellerInitialDiscount = C.rslLicenceDiscount1Y;
      mfrInitialDiscount = C.mfrLicenceDiscount1Y;
    }
    else if (licenceType === '3') {
      resellerInitialLicencePrice = C.rslLicencePrice3Y;
      mfrInitialLicencePrice = C.mfrLicencePrice3Y;
      resellerInitialDiscount = C.rslLicenceDiscount3Y;
      mfrInitialDiscount = C.mfrLicenceDiscount3Y;
    }
    
    // Set FX rate and discount rate
    fxRate = setFxRate(currency);
    
    // Apply incremental discounts by quantity
    if (quantity > 10) {
      let resellerTotalDiscount = (quantity - 10) * resellerInitialDiscount;
      let mfrTotalDiscount = (quantity - 10) * mfrInitialDiscount;

      resellerInitialLicencePrice -= resellerTotalDiscount;
      mfrInitialLicencePrice -= mfrTotalDiscount;
    }  

    const billingTypePremium = (licenceType === '3') ? C.annualBillingDiscount : 0.0;
    
    // Calculate final values
    let resellerCostPerLicence, mfrCostPerLicence;
    
    if (licenceType === '3') {
      resellerCostPerLicence = resellerInitialLicencePrice * fxRate * (1 - discountRate);
      mfrCostPerLicence = mfrInitialLicencePrice * fxRate * (1 - discountRate);
    } else {
      resellerCostPerLicence = resellerInitialLicencePrice * (1 - billingTypePremium) * fxRate * (1 - discountRate);
      mfrCostPerLicence = mfrInitialLicencePrice * (1 - billingTypePremium) * fxRate * (1 - discountRate);
    }
    
    let resellerMargin = ((mfrCostPerLicence - resellerCostPerLicence) / mfrCostPerLicence) * 100;
    
    resellerCostPerLicence = formatNumber(resellerCostPerLicence);
    mfrCostPerLicence = formatNumber(mfrCostPerLicence);
    resellerMargin = formatNumber(resellerMargin);

    let resellerTotalPrice = resellerCostPerLicence * quantity;
    let mfrTotalPrice = mfrCostPerLicence * quantity;

    resellerTotalPrice = formatNumber(resellerTotalPrice);
    mfrTotalPrice = formatNumber(mfrTotalPrice);

    onResultsChange({
      resellerCostPerLicence,
      resellerMargin,
      resellerTotalPrice,
      mfrTotalPrice,
      mfrCostPerLicence,
      quantityExceedLimit,
    });
  }

  return (
    <>
      <div className="form-container">
        <div>
          <label htmlFor="lt">Term</label>
          <select id="lt" name='licenceType' onChange={(e) => setLicenceType(e.target.value)}>
            <option value={1}>1 Year</option>
            <option value={3}>3 Years</option>
          </select>
        </div>
        <div>
          <label htmlFor="q">Quantity</label>
          <input id="q" name='quantity' type="number" placeholder="1" step="1" min="1" max="100" onChange={(e) => setQuantity(e.target.value)} />
        </div>
      </div>
      {quantityExceedLimit && (
        <div className="message-container">
          <p className="message">For projects over 100 licences, <a href="https://www.nowsignage.com/bid-registration" target="_blank" rel="noreferrer" style={{color: '#008EB7', textDecoration: 'none', letterSpacing: '1px'}}>contact us for special bid pricing</a></p>
        </div>
      )}
    </>
  );
}